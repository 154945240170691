import { Link } from 'gatsby'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import peopleData from '../../content/people.json';


import "../scss/pages/index.scss"

class Contact extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			people: peopleData.people,			
		}
	}
  render() {
    const { location, data } = this.props

	console.log(this.props);
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Contact" />
        <div>
			<section className="hero profile" style={{backgroundImage: "url('/img/Contact-header.jpg')"}}>
				<div className="container py-5">
					<div className="row short-top-hero align-items-end">
						<div className="col-lg-10 col-xl-8 d-flex justify-content-between align-items-end">
							<h1 className="h1 pt-5 pl-md-0 mb-0">Contact us today to discuss how our experience can make a difference to you. </h1>
						</div>
						<div className="col-lg-2 col-xl-4 text-right">			
							<Link className="mr-3" to="/contact/"><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></Link>
							<a target="_blank" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>	
						</div>		
					</div>
				</div>
			</section>
			<section className="bg-black fade-black-top pb-5">
				<div className="container mb-5">
					<div className="row">
						{this.state.people.map((person)=>(
							<div className="col-md-6 mb-4 contact-card">
								<div className="bg-white py-4 px-5">
									<div className="d-flex justify-content-between align-items-start flex-column-reverse flex-md-row">
										<h4 className="text-primary">{person.name}</h4>
										<Link to={"/profiles/"+person.slug} className="btn-link btn-xsmall text-primary mb-3 mb-md-0">Visit {person.short_name}’s profile</Link>
									</div>
									<p><i>{person.title}</i></p>
									<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
									<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
									<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
									{/* <p><a className="d-flex align-items-center"><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}
								</div>
							</div>
						))}
						<div className="col-md-6 mb-4 contact-card">
								<div className="bg-primary py-4 px-5">
									<h4 className="text-white mb-4">General Inquiries</h4>
									<p><a className="text-white d-flex align-items-center" href={"tel:416-868-1755"}><img className="icon pr-1" src="/img/icons/white-phone.png"/>416-868-1755</a></p>
									<p><a className="text-white d-flex align-items-center" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png"/>Follow GHW on Twitter</a></p>
								</div>
						</div>
					</div>
				</div>
			</section>
        </div>
      </Layout>
    )
  }
}

export default Contact

